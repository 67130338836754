import React from 'react'
import { graphql } from 'gatsby'
import HeroBanner from '../components/HeroBanner'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import './ofk-workout.scss'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandPointLeft,
  faHandPointRight,
  faBars
} from '@fortawesome/free-solid-svg-icons'

const OfkWorkout = ({ data, pageContext }) => {
  const { next, previous } = pageContext
  const workout = data.wordpressWpOfkWorkout
  return (
    <Layout>
      <div className="OfkWorkout">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OFK Workout of the Day"
          caption="Ready to get your fit on?"
        />
        <div className="container content">
          <Row className="navigationRow">
            <Col md={4}>
              {next ? (
                <Link to={`/ofkworkout/${next.slug}`}>
                  <Button color="link">
                    <FontAwesomeIcon icon={faHandPointLeft} />
                    {next.title}
                  </Button>
                </Link>
              ) : null}
            </Col>
            <Col md={4} className="text-center">
              <Link to="/ofkworkout">
                <Button color="link">
                  <FontAwesomeIcon icon={faBars} />
                  List Workouts
                </Button>
              </Link>
            </Col>
            <Col md={4} className="text-right">
              {previous ? (
                <Link to={`/ofkworkout/${previous.slug}`}>
                  <Button color="link">
                    {previous.title}
                    <FontAwesomeIcon icon={faHandPointRight} />
                  </Button>
                </Link>
              ) : null}
            </Col>
          </Row>
          <div className="container">
            <h1 dangerouslySetInnerHTML={{ __html: workout.title }}></h1>
            <div dangerouslySetInnerHTML={{ __html: workout.content }}></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OfkWorkout

export const query = graphql`
  query OfkWorkout($slug: String!) {
    wordpressWpOfkWorkout(slug: { eq: $slug }) {
      title
      content
    }
    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
